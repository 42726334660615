import { InferType, object, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { CompanyInterface } from '../company/company.interface';
import { StructuredAgreementType } from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';

export type AgreementPartiesView = {
  loanId: string;
  version: number;
  state: StructuredLoanState;
  loan: StructuredAgreementType;
  ownerCompanies: CompanyInterface[];

  // The total commitment is undefined, if no commitments have been configured.
  totalCommitment?: number;
};

export const AgreementPartiesViewRequestValidation = object({
  loanId: string().required(),
});

export type AgreementPartiesViewRequest = InferType<typeof AgreementPartiesViewRequestValidation>;

export const agreementPartiesViewApi = defineApi<{
  body: AgreementPartiesViewRequest;
  response: AgreementPartiesView;
}>({
  url: 'structured-loans/agreement-parties-view',
  method: 'post',
});
