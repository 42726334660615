import { ObjectStripUnknown, TextStripEmpty } from './atoms';
import { literal, sumTypeOptional } from './base';

export const SLInterestPeriodAlignmentValidation = sumTypeOptional('type', {
  utilisation: ObjectStripUnknown({ type: literal('utilisation') }),
  calendar: ObjectStripUnknown({ type: literal('calendar') }),
  custom: ObjectStripUnknown({
    type: literal('custom'),
    date: TextStripEmpty,
  }),
});
