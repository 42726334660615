import { ObjectStripUnknown } from './atoms';
import { literal, sumTypeOptional } from './base';

const ModifiedFollowingBusinessDayDateRolling = ObjectStripUnknown({
  type: literal('modified-next-business-day'),
});

const PrecedingBusinessDayDateRolling = ObjectStripUnknown({
  type: literal('prev-business-day'),
});

export const SlDateRollingValidation = sumTypeOptional('type', {
  'modified-next-business-day': ModifiedFollowingBusinessDayDateRolling,
  'prev-business-day': PrecedingBusinessDayDateRolling,
});
