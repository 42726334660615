import { RsNavigationLink } from '../layout/rs-main-navigation/classes/RsNavigationLink';
import { CompanyInformationType } from '../types/Company';
import ClientPath from './ClientPath';

type SublinkAllow = { [key: string]: boolean };

export function RsApplicationLinks(company?: CompanyInformationType | null) {
  return {
    contact: (allowCondition?: boolean): RsNavigationLink => {
      return new RsNavigationLink('Contact', ClientPath.static.contact).setAllow(allowCondition);
    },
    aboutUs: (allowCondition?: boolean): RsNavigationLink => {
      return new RsNavigationLink('About Us', ClientPath.static.aboutUs).setAllow(allowCondition);
    },
    account: (sublinksAllow: SublinkAllow, allowCondition?: boolean): RsNavigationLink => {
      return new RsNavigationLink('Company', ClientPath.account.index).setAllow(allowCondition).setSubLinks({
        profile: new RsNavigationLink('Profile', ClientPath.account.profile).setAllow(sublinksAllow.profile),
      });
    },
    howItWorks: (sublinksAllow: SublinkAllow, allowCondition?: boolean): RsNavigationLink => {
      return new RsNavigationLink('How it Works', ClientPath.static.howItWorks.base)
        .setAllow(allowCondition)
        .setSubLinks({
          theUnderlying: new RsNavigationLink('The Underlying', ClientPath.static.howItWorks.underlying).setAllow(
            sublinksAllow.theUnderlying
          ),
          technology: new RsNavigationLink('Technology', ClientPath.static.howItWorks.technology).setAllow(
            sublinksAllow.technology
          ),
          loanServiceSpecifics: new RsNavigationLink(
            'Loan Service Specifics',
            ClientPath.static.howItWorks.loanServiceSpecifics
          ).setAllow(sublinksAllow.loanServiceSpecifics),
          depositAlternativeSpecifics: new RsNavigationLink(
            'Deposit Alternative Specifics',
            ClientPath.static.howItWorks.depositAlternativeSpecifics
          ).setAllow(sublinksAllow.depositAlternativeSpecifics),
        });
    },
    company: (sublinksAllow: {
      company?: boolean;
      companyLookup: boolean;
      companyKyc: boolean;
      inviteUsers: boolean;
      companyLabels: boolean;
      integrations: boolean;
      templates: boolean;
    }): RsNavigationLink => {
      return new RsNavigationLink('Company', '').setSubLinks({
        company: new RsNavigationLink(
          // eslint-disable-next-line no-nested-ternary
          company && company.companyName
            ? company.companyName.length > 20
              ? `${company.companyName.slice(0, 20)}...`
              : company.companyName
            : 'Add New Company',
          ClientPath.company.lookup
        ).setAllow(sublinksAllow.company),
        companyLookup: new RsNavigationLink('Company Lookup', ClientPath.company.lookup).setAllow(
          sublinksAllow.companyLookup
        ),
        companyKyc: new RsNavigationLink('Company Kyc Status', ClientPath.company.kycStatus).setAllow(
          sublinksAllow.companyKyc
        ),
        inviteUsers: new RsNavigationLink('Invited Users', ClientPath.company.inviteUsers).setAllow(
          sublinksAllow.inviteUsers
        ),
        companyLabels: new RsNavigationLink('Company Labels', ClientPath.company.labels).setAllow(
          sublinksAllow.companyLabels
        ),
        platformInteractions: new RsNavigationLink('Platform Interactions', ClientPath.company.platformInteractions),
        integrations: new RsNavigationLink('Integrations', ClientPath.company.integrations).setAllow(
          sublinksAllow.integrations
        ),
        templates: new RsNavigationLink('Templates', ClientPath.company.templates).setAllow(sublinksAllow.templates),
      });
    },
    realstocksServices: (sublinksAllow: SublinkAllow, allowCondition?: boolean): RsNavigationLink => {
      return new RsNavigationLink('Services', ClientPath.static.realstocksServices.base)
        .setAllow(allowCondition)
        .setSubLinks({
          marketplace: new RsNavigationLink('Marketplace', 'marketplace').setAllow(sublinksAllow.marketplace),
          negotiation: new RsNavigationLink('Negotiation', 'negotiation').setAllow(sublinksAllow.negotiation),
          administration: new RsNavigationLink('Administration', 'administration').setAllow(
            sublinksAllow.administration
          ),
          transfers: new RsNavigationLink('Transfers', 'transfers').setAllow(sublinksAllow.transfers),
          depositAlternative: new RsNavigationLink('Deposit Alternative', 'deposit-alternative').setAllow(
            sublinksAllow.depositAlternative
          ),
        });
    },
    howItWorksNegotiation: (sublinksAllow: SublinkAllow, allowCondition?: boolean): RsNavigationLink => {
      return new RsNavigationLink('How it Works', ClientPath.modules.negotiation.howItWorks.base)
        .setAllow(allowCondition)
        .setSubLinks({
          marketplace: new RsNavigationLink(
            'Marketplace',
            ClientPath.modules.negotiation.howItWorks.marketplace
          ).setAllow(sublinksAllow.theUnderlying),
          negotiation: new RsNavigationLink(
            'Negotiation',
            ClientPath.modules.negotiation.howItWorks.negotiation
          ).setAllow(sublinksAllow.technology),
          administration: new RsNavigationLink(
            'Administration',
            ClientPath.modules.negotiation.howItWorks.administration
          ).setAllow(sublinksAllow.loanServiceSpecifics),
          transfers: new RsNavigationLink('Transfers', ClientPath.modules.negotiation.howItWorks.transfers).setAllow(
            sublinksAllow.depositAlternativeSpecifics
          ),
          depositAlternative: new RsNavigationLink(
            'Deposit Alternative',
            ClientPath.modules.negotiation.howItWorks.depositAlternative
          ).setAllow(sublinksAllow.depositAlternative),
        });
    },
    howItWorksLoanApplication: (sublinksAllow: SublinkAllow, allowCondition?: boolean): RsNavigationLink => {
      return new RsNavigationLink('How it Works', ClientPath.modules.loanApplication.howItWorks.base)
        .setAllow(allowCondition)
        .setSubLinks({
          marketplace: new RsNavigationLink(
            'Marketplace',
            ClientPath.modules.loanApplication.howItWorks.marketplace
          ).setAllow(sublinksAllow.theUnderlying),
          negotiation: new RsNavigationLink(
            'Negotiation',
            ClientPath.modules.loanApplication.howItWorks.negotiation
          ).setAllow(sublinksAllow.technology),
          administration: new RsNavigationLink(
            'Administration',
            ClientPath.modules.loanApplication.howItWorks.administration
          ).setAllow(sublinksAllow.loanServiceSpecifics),
          transfers: new RsNavigationLink(
            'Transfers',
            ClientPath.modules.loanApplication.howItWorks.transfers
          ).setAllow(sublinksAllow.depositAlternativeSpecifics),
          depositAlternative: new RsNavigationLink(
            'Deposit Alternative',
            ClientPath.modules.loanApplication.howItWorks.depositAlternative
          ).setAllow(sublinksAllow.depositAlternative),
        });
    },
    tos: (sublinksAllow: SublinkAllow, allowCondition?: boolean, search?: string, _state?: any) => {
      return new RsNavigationLink('Terms of Service', ClientPath.static.tos).setAllow(allowCondition).setSubLinks({
        lender: new RsNavigationLink('Lender Terms of Service', ClientPath.static.lenderTos)
          .setAllow(sublinksAllow.lender)
          .setSearch(search),
        borrower: new RsNavigationLink('Borrower Terms of Service', ClientPath.static.borrowerTos)
          .setAllow(sublinksAllow.borrower)
          .setSearch(search),
      });
    },
    realstocksAdmin: (sublinksAllow: SublinkAllow, allowCondition?: boolean) => {
      return new RsNavigationLink('Platform Admin', ClientPath.realstocksAdmin.index)
        .setAllow(allowCondition)
        .setSubLinks({
          companies: new RsNavigationLink('Companies', ClientPath.realstocksAdmin.companies).setAllow(
            sublinksAllow.companies
          ),
          users: new RsNavigationLink('Users', ClientPath.realstocksAdmin.users).setAllow(sublinksAllow.users),
          templates: new RsNavigationLink('Templates', ClientPath.realstocksAdmin.templates).setAllow(
            sublinksAllow.loans
          ),
          docusign: new RsNavigationLink('DocuSign', ClientPath.realstocksAdmin.docusign).setAllow(
            sublinksAllow.docusign
          ),
          migrations: new RsNavigationLink('Migrations', ClientPath.realstocksAdmin.migrations).setAllow(
            sublinksAllow.migrations
          ),
          indexManagement: new RsNavigationLink(
            'Index Management',
            ClientPath.realstocksAdmin.indexManagement
          ).setAllow(sublinksAllow.indexManagement),
        });
    },
  };
}
