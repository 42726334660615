import { defineApi } from '../common/api-definition';

export type DataExportVersion = '1.0' | '1.1';
export type DataExportObjectType = 'loan-meta' | 'loan-configuration' | 'loan-events' | 'company';

const getSchema = defineApi<{
  params: {
    version: DataExportVersion;
    objectType: DataExportObjectType;
  };
}>({ url: 'data-export/:version/schema/:objectType', method: 'get' });

export const dataExportApi = {
  getSchema,
};
