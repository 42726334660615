import { entries } from '../utils/type-utils';

/**
 * Access rights available for each module, including the object types.
 *
 * Remember to also add new ones to the roles in `role-definitions.ts`,
 * otherwise noone will have the access right.
 *
 * This list is only used to make it easier to write the access right
 * you expect. It is not persisted anywhere.
 *
 * Structure of this object is:
 * 'module-name': {
 *    'object-type': {
 *       'action-name': any value it doesn't matter
 *    }
 * }
 */
export const moduleActions = {
  'platform-admin': {
    multiple: {
      'read-users': '',
      'read-company': '',
      'cancel-company': '',
      'submit-company': '',
      'onboard-company': '',
      'approve-onboarding': '',
      'reject-onboarding': '',
      'approve-kyc': '',
      'manage-company-offerings': '',
      'upload-templates': '',
      'approve-transfer': '',
      'manage-index': '',
      'set-platform-default-dashboard': '',
    },
  },
  'company-admin': {
    company: {
      read: '',
      create: '',
      submit: '',
      'update-details': '',
      'upload-documents': '',
      'invite-user': '',
      'remove-user': '',
      'update-user-access-rights': '',
      'manage-labels': '',
      'manage-data-export': '',
      'manage-loan-reconciliation': '',
      'manage-property-valuation-integration': '',
      'manage-base-rate-integration': '',
    },
  },
  marketplace: {
    'loan-application': {
      read: '',
      new: '',
      update: '',
      cancel: '',
      ready: '',
      'change-needed': '',
      post: '',
      'abort-post': '',
      'force-close-post': '',
      'extend-post': '',
      'revive-aborted-post': '',
      'change-audience': '',
      'bid-event': '',
      'assign-labels': '',
    },
    bid: {
      read: '',
      open: '',
      'sign-nda': '',
      update: '',
      submit: '',
      withdraw: '',
      reject: '',
      'request-improvement': '',
    },
  },
  negotiation: {
    'loan-negotiation': {
      read: '',
      new: '',
      'update-commercial-terms-and-documents': '',
      'update-status': '',
      'update-legal-terms': '',
      'accept-reject-counter-approve': '',
      'update-cp-category': '',
      'send-indicative-offer': '',
      'open-legal-terms': '',
      'sign-facility-agreement': '',
      'assign-labels': '',
    },
  },
  administration: {
    loan: {
      read: '',
      new: '',
      'import-loan': '',
      'export-loan': '',
      'configure-loan': '',
      'abort-loan': '',
      'delete-loan': '',
      'instruct-payment': '',
      'settle-payment': '',
      'create-voting-event': '',
      'cancel-voting-event': '',
      'cast-vote': '',
      'initiate-prepayment': '',
      'start-loan-amend': '',
      'cancel-loan-amend': '',
      'commit-loan-amend': '',
      'initiate-termination': '',
      'update-covenant-details': '',
      'sign-compliance-certificate': '',
      'assign-labels': '',
      'manage-outbound-notifications': '',
      'reconcile-loans': '',
      'generate-outbound-notification-for-covenant': '',
      'fetch-property-valuation': '',
      'apply-automatic-commands': '',
    },
    multiple: {
      'view-dashboard': '',
      'edit-dashboard': '',
      'manage-noc-companies': '',
      'view-base-rates': '',
      'set-base-rate': '',
      'use-chatbot': '',
      'read-template': '',
      'write-template': '',
    },
  },
} as const;

export type ModuleActions = typeof moduleActions;
export type ModuleAccessActionsI = ModuleActions;

type Module = keyof ModuleActions;
type ObjectType<T extends Module> = keyof ModuleActions[T];
type Action<T extends Module, U extends ObjectType<T>> = keyof ModuleActions[T][U];

type ActionDefinitions = {
  [TModule in Module]: {
    [TObjectType in ObjectType<TModule>]: {
      [TAction in Action<TModule, TObjectType>]: { module: TModule; objectType: TObjectType; action: TAction };
    };
  };
};

export const actionDefs = Object.fromEntries(
  entries(moduleActions).map(([module, objectTypes]) => [
    module,
    Object.fromEntries(
      entries(objectTypes).map(([objectType, actions]) => [
        objectType,
        Object.fromEntries(entries(actions).map(([action]) => [action, { module, objectType, action }])),
      ])
    ),
  ])
) as unknown as ActionDefinitions;

export type CompanyTypeCombinations =
  | 'borrower'
  | 'platform_admin'
  | 'agent'
  | 'lender'
  | 'borrower,lender'
  | 'borrower,lender,agent'
  | 'borrower,lender,platform_admin'
  | 'borrower,lender,platform_admin,agent'
  | 'borrower,platform_admin,agent'
  | 'borrower,platform_admin'
  | 'borrower,agent'
  | 'lender,platform_admin'
  | 'lender,platform_admin,agent'
  | 'lender,agent'
  | 'platform_admin,agent';

export type AccessRightScopeType =
  | { type: 'all' }
  | { type: 'objects'; ids: string[] }
  | { type: 'labels'; labels: string[] };
