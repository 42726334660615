import * as Yup from 'yup';
import { ref } from 'yup';
import { NumberStripEmpty, ObjectStripUnknown } from './atoms';
import { literal, sumTypeRequired } from './base';
import { BaseRate, optionStripEmpty } from './options';

const FixedInterestRateValidation = ObjectStripUnknown({
  type: literal('fixed'),
  rate: NumberStripEmpty.min(0),
});

const FloatingInterestRateValidation = ObjectStripUnknown({
  type: literal('floating'),
  'base-rate-name': optionStripEmpty(BaseRate),
  'base-rate-floored': Yup.bool(),
  'base-rate-floor': NumberStripEmpty.nullable()
    .min(0, 'Floor value must be positive number!')
    .max(100, 'Floor value must be smaller than 100%!'),
  'base-rate-ceiling': NumberStripEmpty.nullable()
    .min(0, 'Cap value must be positive number!')
    .max(100, 'Cap value must be smaller than 100%!')
    .moreThan(ref('base-rate-floor'), 'Cap value must be bigger than floor!'),
  margin: NumberStripEmpty.min(0),
  'quotation-days': NumberStripEmpty.integer().min(0),
});

const CustomInterestRateValidation = ObjectStripUnknown({
  type: literal('custom'),
  // The schedule is saved elsewhere and is therefore not validated.
});

const CustomDynamicInterestRateValidation = ObjectStripUnknown({
  type: literal('custom-dynamic'),
});

export const SLInterestRateValidation = sumTypeRequired('type', {
  fixed: FixedInterestRateValidation,
  floating: FloatingInterestRateValidation,
  custom: CustomInterestRateValidation,
  'custom-dynamic': CustomDynamicInterestRateValidation,
});
