import { defineApi } from '../common/api-definition';
import { Option } from '../common/Option';
import { LoanMetricDraftType } from '../covenants/LoanMetricType';
import { SLReportingEventConfigDraft } from '../covenants/ReportingEventConfigType';
import { SLFacilityViewType } from './SLFacilityViewType';
import { StructuredLoanState } from './structured-loan-state';

export type AgreementReportingEventView = {
  loanId: string;
  reId: string;
  version: number;
  state: StructuredLoanState;
  re: SLReportingEventConfigDraft;
  metrics: LoanMetricDraftType[];
  facilities: SLFacilityViewType[];
  templateOptions: Option[];
};

export const agreementReportingEventViewApi = defineApi<{
  body: { loanId: string; reId: string };
  response: AgreementReportingEventView;
}>({
  url: 'structured-loans/agreement-reporting-event-view',
  method: 'post',
});
