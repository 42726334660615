import { Details, matchString } from '@realstocks/types';
import { PageMode } from '../types/page-mode.type';

const negotiationBasePath = '/app/negotiation';
const loanApplicationBasePath = '/app/marketplace';
const loanDetailsBasePath = '/app/administration/loan-details';

const makePastEventLinkByType = (loanId: string, eventId: string, type: Details['type']) => {
  const path = matchString(type, {
    transaction: () => 'transaction',
    'metric-collection': () => 'metric-collection',
    'covenant-collection': () => 'covenant-collection',
    'sl-covenant-report': () => 'reporting-event',
    'sl-information-undertaking': () => 'information-undertaking',
    'initiate-utilisation': () => 'initiate-utilisation',
    'initiate-drawdown': () => 'initiate-drawdown',
    'mortgage-bond-coupon': () => 'custom-dynamic-period',
    'base-rate': () => 'base-rate',
  });
  if (!path) return undefined;
  return `/app/sl-administration/administration/past-events/loan/${loanId}/event/${eventId}/${path}`;
};

const ClientPath = {
  // Add here modules paths
  modules: {
    slAdministration: {
      index: '/app/sl-administration',
      details: '/app/sl-administration/loan-details',
      newLoan: '/app/sl-administration/new-loan',

      makeRunningStatusLink: (loanId: string) => `/app/sl-administration/loan-details/${loanId}/status`,
      makeTermSheetLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}`,

      makeOverviewLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/overview`,

      makePartiesLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/parties`,

      makeStructureLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/structure`,

      makeFeesAndHedgingLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/fees-and-hedging`,

      makeDocumentsAndSecuritiesLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/documents-and-securities`,

      makeDistributionAndAccountsLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/distribution-and-accounts`,

      makeMetricLink: (loanId: string, metricId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/metric/${metricId}`,

      makeIULink: (loanId: string, iuId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/information-undertaking/${iuId}`,

      makeRELink: (loanId: string, reId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/reporting-event/${reId}`,

      makeFacilityOverviewLink: (loanId: string, facilityId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/facility/${facilityId}/overview`,

      makeFacilityIntroLink: (loanId: string, facilityId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/facility/${facilityId}/intro`,

      makeFacilityPropertyLink: (loanId: string, facilityId: string, propertyId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/facility/${facilityId}/property/${propertyId}`,

      makeSequenceUtilisationLink: (
        loanId: string,
        facilityId: string,
        sequenceId: string,
        utilisationId: string,
        mode: PageMode
      ) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/facility/${facilityId}/sequence/${sequenceId}/utilisation/${utilisationId}`,

      makeSequenceTermSheetLink: (
        loanId: string,
        facilityId: string,
        sequenceId: string,
        termsId: string,
        mode: PageMode
      ) =>
        `/app/sl-administration/loan-details/${loanId}/term-sheet/${mode}/facility/${facilityId}/sequence/${sequenceId}/tranche/${termsId}`,

      makeEventsLink: () => '/app/sl-administration/administration/events',

      makePastEventsLink: () => '/app/sl-administration/administration/past-events',

      makePastEventLink: (eventId: string | undefined, details: Details | undefined) => {
        if (eventId === undefined || details === undefined) return undefined;
        return makePastEventLinkByType(details.loanId, eventId, details.type);
      },

      makePastEventLinkByType,

      makePaymentEventsLink: () => '/app/sl-administration/administration/payment-events',

      makeCustomDynamicScheduleLink: (loanId: string, sequenceId: string, periodIndex: number) =>
        `/app/sl-administration/administration/events/loan/${loanId}/sequence/${sequenceId}/custom-dynamic-period/${periodIndex}`,

      makeTransactionLink: (loanId: string, sequenceId: string, transactionId: string) =>
        `/app/sl-administration/administration/payment-events/loan/${loanId}/sequence/${sequenceId}/transaction/${transactionId}`,

      makeStartLink: (loanId: string, mode: PageMode) => `/app/sl-administration/loan-details/${loanId}/start/${mode}`,

      makeStartPartiesLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/start/${mode}/parties`,

      makeStartNotificationsLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/start/${mode}/custom-platform-notifications`,

      makeStartUtilisationLink: (loanId: string, facilityId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/start/${mode}/facility/${facilityId}/utilisation`,

      makeSequenceUtilisationAndStartConfigurationLink: (
        loanId: string,
        facilityId: string,
        sequenceId: string,
        mode: PageMode
      ) =>
        `/app/sl-administration/loan-details/${loanId}/start/${mode}/facility/${facilityId}/sequence/${sequenceId}/utilisation-and-start-configuration`,

      makeStartBookingModelLink: (loanId: string, facilityId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/start/${mode}/facility/${facilityId}/booking-model`,

      covenantChecks: '/app/sl-administration/administration/covenant-checks',

      reports: '/app/sl-administration/administration/reports',

      makeMetricCollectionLink: (loanId: string, checkDate: string) =>
        `/app/sl-administration/administration/covenant-checks/${loanId}/check-date/${checkDate}`,

      makeCovenantCollectionLink: (loanId: string, covenantId: string, checkDate: string) =>
        `/app/sl-administration/administration/covenant-checks/${loanId}/covenant/${covenantId}/check-date/${checkDate}`,

      makeInformationUndertakingDetailsLink: (loanId: string, informationUndertakingId: string, checkDate: string) =>
        `/app/sl-administration/administration/covenant-checks/${loanId}/information-undertaking/${informationUndertakingId}/check-date/${checkDate}`,

      makeReportingEventLink: (loanId: string, reportingEventId: string, checkDate: string) =>
        `/app/sl-administration/administration/covenant-checks/${loanId}/reporting-event/${reportingEventId}/check-date/${checkDate}`,

      makeInitiateUtilisationLink: (loanId: string, sequenceId: string) =>
        `/app/sl-administration/administration/payment-events/loan/${loanId}/sequence/${sequenceId}/initiate-utilisation`,

      makeInitiateDrawdownLink: (loanId: string, sequenceId: string, drawdownId: string) =>
        `/app/sl-administration/administration/payment-events/loan/${loanId}/sequence/${sequenceId}/initiate-drawdown/${drawdownId}`,

      makeStartExernalIDsLink: (loanId: string, mode: PageMode) =>
        `/app/sl-administration/loan-details/${loanId}/start/${mode}/external-ids`,

      dashboards: {
        root: `/app/sl-administration/dashboard/default`,
        management: `/app/sl-administration/dashboard/manage`,
        create: `/app/sl-administration/dashboard/create`,
        view: (dashboardId: string) => `/app/sl-administration/dashboard/${dashboardId}`,
        edit: (dashboardId: string) => `/app/sl-administration/dashboard/${dashboardId}/edit`,
      },
      baseRateAdministration: {
        index: (currency?: string, quotationDate?: string, period?: string, rateName?: string) =>
          `/app/sl-administration/base-rate?highlight=${[rateName, currency, period, quotationDate]
            .filter(Boolean)
            .join('-')}`,
        create: '/app/sl-administration/base-rate/create',
        edit: (baseRateId: string) => `/app/sl-administration/base-rate/${baseRateId}/edit`,
      },
      nocs: {
        root: '/app/sl-administration/nocs',
        create: '/app/sl-administration/nocs/create',
        edit: (nocsId: string) => `/app/sl-administration/nocs/${nocsId}/edit`,
      },
    },
    // TODO: Remove all uses of these paths to 1LS loans
    // https://realstocks.atlassian.net/browse/SD-1334
    administration: {
      loanAdministration: {
        index: 'app/administration/loan-administration',
        upcomingEvents: '/app/administration/loan-administration/upcoming-events',
        payments: '/app/administration/loan-administration/payments',
        covenantChecks: '/app/administration/loan-administration/covenant-checks',
        makeCovenantDetailsLink: (loanId: string, date: string) =>
          `/app/administration/loan-administration/covenant-checks/${loanId}/date/${date}`,
      },
      loanDetails: {
        makeBasicInfoLink: (loanId: string, type: 'view' | 'edit' = 'view') => {
          return {
            pathname: `${loanDetailsBasePath}/${loanId}/term-sheet/${type}/basic-info`,
            state: { from: 'side-navigation' },
          };
        },
      },
    },
    negotiation: {
      index: negotiationBasePath,
      overview: negotiationBasePath,
      newRequest: `${negotiationBasePath}/new-request`,
      viewBase: `${negotiationBasePath}/negotiation-details/:loanId`,

      state: `${negotiationBasePath}/negotiation-details/:loanId/status`,
      makeStateLink: (loanId: string) => `${negotiationBasePath}/negotiation-details/${loanId}/status`,

      basicInfo: `${negotiationBasePath}/negotiation-details/:loanId/basic-info`,
      makeBasicInfoLink: (loanId: string) => `${negotiationBasePath}/negotiation-details/${loanId}/basic-info`,

      securityPackageAndCovenants: `${negotiationBasePath}/negotiation-details/:loanId/security-package-and-covenants`,
      makeSecurityPackageAndCovenantsLink: (loanId: string) =>
        `${negotiationBasePath}/negotiation-details/${loanId}/security-package-and-covenants`,

      termsAndConditions: `${negotiationBasePath}/negotiation-details/:loanId/terms-and-conditions`,
      makeTermsAndConditionsLink: (loanId: string) =>
        `${negotiationBasePath}/negotiation-details/${loanId}/terms-and-conditions`,

      documents: `${negotiationBasePath}/negotiation-details/:loanId/documents`,
      makeDocumentsLink: (loanId: string) => `${negotiationBasePath}/negotiation-details/${loanId}/documents`,

      termSheet: `${negotiationBasePath}/negotiation-details/:loanId/commercial-term-sheet`,
      makeTermSheetLink: (loanId: string) =>
        `${negotiationBasePath}/negotiation-details/${loanId}/commercial-term-sheet`,

      agreement: `${negotiationBasePath}/negotiation-details/:loanId/agreement`,
      makeAgreementLink: (loanId: string) => `${negotiationBasePath}/negotiation-details/${loanId}/agreement`,

      history: `${negotiationBasePath}/negotiation-details/:loanId/history`,
      makeHistoryLink: (loanId: string) => `${negotiationBasePath}/negotiation-details/${loanId}/history`,
      howItWorks: {
        base: `${negotiationBasePath}/services`,
        marketplace: `${negotiationBasePath}/services/marketplace`,
        negotiation: `${negotiationBasePath}/services/negotiation`,
        administration: `${negotiationBasePath}/services/administration`,
        transfers: `${negotiationBasePath}/services/transfers`,
        depositAlternative: `${negotiationBasePath}/services/deposit-alternative`,
      },
    },
    loanApplication: {
      index: loanApplicationBasePath,

      makeConfigurationLink: (id: string) => `${loanApplicationBasePath}/details/${id}/configuration`,
      makeConfigurationLinkLender: (id: string) => `${loanApplicationBasePath}/details-lender/${id}/configuration`,

      makeDocumentsLink: (id: string) => `${loanApplicationBasePath}/details/${id}/documents`,
      makeDocumentsLinkLender: (id: string) => `${loanApplicationBasePath}/details-lender/${id}/documents`,

      makeBidsLink: (id: string) => `${loanApplicationBasePath}/details/${id}/bids`,
      makeBidComparisonLink: (id: string) => `${loanApplicationBasePath}/details/${id}/bids/comparison`,
      makeBidLink: (id: string, bidId: string) => `${loanApplicationBasePath}/details/${id}/bids/${bidId}`,

      makeBidLinkLender: (id: string) => `${loanApplicationBasePath}/details-lender/${id}/bid`,

      makeHistoryLink: (id: string) => `${loanApplicationBasePath}/details/${id}/history`,
      makeHistoryLinkLender: (id: string) => `${loanApplicationBasePath}/details-lender/${id}/history`,

      howItWorks: {
        base: `${loanApplicationBasePath}/services`,
        marketplace: `${loanApplicationBasePath}/services/marketplace`,
        negotiation: `${loanApplicationBasePath}/services/negotiation`,
        administration: `${loanApplicationBasePath}/services/administration`,
        transfers: `${loanApplicationBasePath}/services/transfers`,
        depositAlternative: `${loanApplicationBasePath}/services/deposit-alternative`,
      },
    },
  },
  // end modules paths
  home: '/',
  notFound: '/not-found',
  portfolio: {
    index: '/app/portfolio',
    welcome: '/app/portfolio/welcome',
    holdings: '/app/portfolio/holdings',
    transactions: '/app/portfolio/transactions',
    history: '/app/portfolio/history',
  },
  account: {
    index: '/app/account',
    profile: '/app/account/profile',
    myAccessRights: '/app/account/access-rights-within-company',
  },
  company: {
    index: '/app/company',
    view: '/app/company/view',
    create: (duns?: number | 'false') => {
      return `/app/company/create${duns ? `/${duns}?` : ''}`;
    },
    edit: (step: number) => {
      return `/app/company/edit/${step}`;
    },
    createBase: '/app/company/create/:duns?',
    editBase: '/app/company/edit/:step',
    lookup: '/app/company/lookup',
    inviteUsers: '/app/company/invited-users',
    kycStatus: '/app/company/kyc-status',
    labels: '/app/company/labels',
    platformInteractions: '/app/company/platform-interactions',
    integrations: '/app/company/integrations',
    templates: '/app/company/templates',
  },
  auth: {
    login: '/login',
    bankEidConfirmation: '/bank-eid-confirmation/:type',
    signup: '/signup',
    verifyAccount: '/verify-account',
    forgotPassword: '/forgot-password',
    resendVerificationCode: '/resend-verification-code',
    linkBankId: '/link-bankid',
    loginWithBankid: '/login-with-bankid',
  },
  error: {
    accessDenied: '/access-denied',
    somethingWentWrong: '/something-went-wrong',
  },
  loading: '/loading-content',
  static: {
    realstocksServices: {
      base: '/services',
      marketplace: '/services/marketplace',
      negotiation: '/services/negotiation',
      administration: '/services/administration',
      transfers: '/services/transfers',
      depositAlternative: '/services/deposit-alternative',
    },
    howItWorks: {
      base: '/how-it-works',
      underlying: '/how-it-works/the-underlying',
      technology: '/how-it-works/technology',
      loanServiceSpecifics: '/how-it-works/loan-service-specifics',
      depositAlternativeSpecifics: '/how-it-works/deposit-alternative-specifics',
    },
    privacyPolicy: '/privacy-policy',
    marketplace: '/marketplace',
    aboutUs: '/about-us',
    contact: '/contact',
    demoAccount: '/demo-account',
    tos: '/terms-of-service',
    lenderTos: '/terms-of-service/lender',
    borrowerTos: '/terms-of-service/borrower',
    professionalInvestorStatus: '/act-on-investment-services',
  },
  trade: {
    index: '/app/trade',
    openMarket: '/app/trade/open-market',
    tradeLogs: '/app/trade/app/trade-logs',
  },
  realstocksAdmin: {
    index: '/app/platform-admin',
    nocs: '/app/platform-admin/nocs',
    editNoc: (companyId: string) => `/app/platform-admin/nocs/${companyId}`,
    companies: '/app/platform-admin/companies',
    company: (id: string) => `/app/platform-admin/companies/${id}`,
    companyBase: '/app/platform-admin/companies/:companyId',
    users: '/app/platform-admin/users',
    user: (base64User: string) => `/app/platform-admin/users/${base64User}`,
    userBase: '/app/platform-admin/users/:user',
    templates: '/app/platform-admin/templates',
    docusign: '/app/platform-admin/docusign',
    migrations: '/app/platform-admin/migrations',
    indexManagement: '/app/platform-admin/index-management',
  },
  admin: {
    reviewOrders: '/app/trade/review-orders',
    payments: '/app/trade/payments',
  },
  loans: {
    index: '/app/loans',
    welcome: '/app/loans/welcome',
    nda: `/app/loan/nda/:loanId`,
    makeNdaLink: (loanId: string) => `/app/loan/nda/${loanId}`,

    pendingRequests: '/app/loans/pending-requests',
  },
};

export default ClientPath;
